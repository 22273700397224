// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-map-js": () => import("./../../../src/pages/interactive-map.js" /* webpackChunkName: "component---src-pages-interactive-map-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-school-year-programs-kids-weekly-events-listing-js": () => import("./../../../src/pages/school-year-programs/kids-weekly-events-listing.js" /* webpackChunkName: "component---src-pages-school-year-programs-kids-weekly-events-listing-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/aboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-school-year-page-js": () => import("./../../../src/templates/schoolYearPage.js" /* webpackChunkName: "component---src-templates-school-year-page-js" */),
  "component---src-templates-staff-page-js": () => import("./../../../src/templates/staffPage.js" /* webpackChunkName: "component---src-templates-staff-page-js" */),
  "component---src-templates-summer-camp-page-js": () => import("./../../../src/templates/summerCampPage.js" /* webpackChunkName: "component---src-templates-summer-camp-page-js" */)
}

